import React, { useState } from "react";
import LogInForm from "../components/logInForm/logInForm";
import styles from "./LoginPage.module.css";
import { Link } from "react-router-dom";

export const LoginPage = (props) => {

    const { accounts } = props;
    
    return(
        <div style={styles}>
            <LogInForm accounts={accounts} />
            <Link to="/account" style={{ textDecoration: 'none', color:"white" }} state={{foundAccount:null}}><button>Create Account</button></Link>
        </div>
    );
}