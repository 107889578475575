import {  Outlet, NavLink } from "react-router-dom";

export const ROUTES = {
    LOGIN: "/login",
    REQUESTLEAVE: "/requestleave",
    MANAGEREQUESTS: "/managerequests",
    ACCOUNT: "/account",
    HOME: "/home",
    CALENDAR: "/calendar"
  };

function Root() {
    return (
        <>
            <nav>
                <NavLink to={ROUTES.REQUESTLEAVE} >
                Log In
                </NavLink>
            </nav>
            <Outlet/>
      </>
    );

}

export default Root;