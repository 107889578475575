import React, { useState, useEffect } from "react";
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route, Navigate } from "react-router-dom"
import Root, { ROUTES } from "./components/root/Root";
import { RequestLeavePage } from "./containers/RequestLeavePage";
import { LoginPage } from "./containers/LoginPage";
import ManageRequestsPage from "./containers/ManageRequestsPage";
import { getAccounts, getRequests } from "./api";
import AccountForm from "./components/accountForm/AccountForm";
import { HomePage } from "./containers/HomePage";
import EventCalendar from "./components/eventCalendar/EventCalendar";

export const App = () => {

  const [requests, setRequests] = useState([]);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    async function loadAllRequests() {
      let requestData = await getRequests();
      if(requestData) {
        setRequests(requestData);
      }
    }

    loadAllRequests();
  }, [requests])

  useEffect(() => {
    async function loadAllAccounts() {
      let accountData = await getAccounts();
      if(accountData) {
        setAccounts(accountData);
      }
    }

    loadAllAccounts();

  },[accounts])

  const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/">
      <Route index element={ <Navigate to={ROUTES.LOGIN} replace/> }/>
      <Route path={ROUTES.LOGIN} element={ <LoginPage accounts={accounts}/>}/>
      <Route path={ROUTES.HOME} element={<HomePage requests={requests}/>}/>
      <Route path={ROUTES.REQUESTLEAVE} element={ <RequestLeavePage requests={requests} accounts={accounts}/>}/>
      <Route path={ROUTES.MANAGEREQUESTS} element={ <ManageRequestsPage requests={requests} accounts={accounts}/>}/>
      <Route path={ROUTES.ACCOUNT} element={<AccountForm accounts={accounts}/>}/>
      <Route path={ROUTES.CALENDAR} element={<EventCalendar requests={requests}/>}/>
    </Route>
  ));
  
  return (
    <RouterProvider router={router}/>
  );
};

