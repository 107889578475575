import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function LogInForm(props){

    const { accounts } = props;
    const[email,setEmail] = useState("");
    const[password,setPassword] = useState("");

    const navigate = useNavigate();
    const navigateToHome = () => navigate('/home',{state:{foundAccount: accounts.find((account) => account.email === email)}});

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(accounts);
        const foundAccount = accounts.find((account) => account.email === email);
        if(foundAccount)
        {
            if(foundAccount.password === password)
            {
                console.log(foundAccount);
                navigateToHome();
            }
            else
            {
                alert("Email or password is incorrect");
            }
        }
        else{
            alert("Email or password is incorrect");
        }
      };

    function csvToArr(stringVal, splitter) {
        const [keys, ...rest] = stringVal
            .trim()
            .split("\n")
            .map((item) => item.split(splitter));
        
        const formedArr = rest.map((item) => {
            const object = {};
            keys.forEach((key, index) => (object[key] = item.at(index)));
            return object;
        });
        return formedArr;
    }

    return(
        <form onSubmit={handleSubmit} style={{margin:"auto"}}>
            <label>Log In Page</label>
            
            <input 
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="example@email.com"
            />
            <input
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
            />
            <button type="submit">Log In </button>
        </form>
    )
}

export default LogInForm;