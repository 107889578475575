import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment';
import React, { useEffect, useState, useCallback } from "react";

function EventCalendar (props) {
  const { requests } = props;

  const localizer = momentLocalizer(moment);
  const [events,setEvents] = useState([]);
  const [delay,setDelay] = useState(10)

  useEffect(() => {
    //requests.filter(filteredRequest => filteredRequest.approved === false);
    async function loadCalendarData() {
      let calendarData = [];
      requests.filter(filteredRequest => filteredRequest.approved === true).map((request) =>{
        let calendarObject = {
          id: request.id,
          title: request.name + " : From " + request.startDate + " to " + request.endDate,
          start: moment(request.startDate).toDate(),
          end: moment(request.endDate).toDate(),
        }
        calendarData.push(calendarObject);
      });
      setEvents(calendarData);
    }

    loadCalendarData();
    if(delay > 0){
      setDelay(delay-1);
    }
    //console.log(delay);
  }, [delay]);

  const handleSelectEvent = useCallback(
    (event) => window.alert(event.title)
  )
  
  return(
  <div>
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      showMultiDayTimes
      onSelectEvent={handleSelectEvent}
      style={{
        height: 900,
        background:"white"
      }}
    />
  </div>
  )
}

export default EventCalendar;