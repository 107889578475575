import React, { useState } from "react";
import styles from "./LoginPage.module.css";
import RequestForm from "../components/requestForm/requestForm";
import ManageRequestsPage from "./ManageRequestsPage";
import {EventCalendar} from "../components/eventCalendar/EventCalendar";

export const RequestLeavePage = (props) => {
    const { requests, accounts } = props;
    const [sickRequest,setSickRequest] = useState(false);

    const handleRequestChange = (e) => {
        setSickRequest(!sickRequest);
    }

    return(
        <div style={styles}>
            <button onClick={handleRequestChange} style={{display:"block"}}>PTO/Sick Request</button>
            <RequestForm sickRequest={sickRequest}/>
        </div>
    );
}